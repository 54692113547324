import React from "react";
import MailForm from "./MailForm";
import { motion } from "framer-motion";

const UslugePaketi = () => {
  // Animation Variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.3 } },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 80 },
    },
  };

  const listItemVariants = {
    hidden: { opacity: 0, x: -30 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  const underlineEffect = {
    hidden: { scaleX: 0 },
    visible: { scaleX: 1, transition: { duration: 0.3 } },
  };
  return (
    <>
      <div className="home" style={{ height: "100vh" }}></div>
      <div className="container nopad">
        <div class="pricing4 py-5">
          <div class="container">
            <div class="row mt-4">
              <div class="col-md-4">
                <div class="card card-shadow border-0 mb-4">
                  <img
                    class="card-img-top"
                    src="/img/STARTERPACK.gif"
                    alt="business boost"
                  />
                  <div class="p-3">
                    <h5 class="font-weight-medium mb-0">STARTER PAKET</h5>
                    <ul class="list-inline font-14 mt-3 mediafix">
                      <li class="py-1">
                        ✅ Izrada web stranice (bez načina plaćanja i baze
                        podataka)
                      </li>
                      <li class="py-1">✅ Hosting </li>
                      <li class="py-1">✅ Domen (.com ili .rs) </li>
                      <li class="py-1">
                        ✅ SEO optimizacija za poboljšanu vidljivost
                      </li>
                    </ul>
                  </div>

                  {/* <div class="d-flex flex-column justify-content-between mt-auto">
                  <div className="text-center">
                    <h2 class="price">130€</h2>
                    <a
                      class="btn btn-danger-gradiant rounded-pill text-white btn-md border-0"
                      
                    >
                      200€
                      
                    </a>
                  </div>
                </div> */}
                </div>
              </div>

              <div class="col-md-4">
                <div class="card card-shadow border-0 mb-4">
                  <img
                    class="card-img-top"
                    src="/img/BIZNISBOOST.gif"
                    alt="business boost"
                  />
                  <div class="p-3">
                    <h5 class="font-weight-medium mb-0">BUSINESS BOOST</h5>
                    <ul class="list-inline font-14 mt-3">
                      <li class="py-1">✅ STARTER PAKET +</li>
                      <li class="py-1">✅ Održavanje web stranice</li>
                      <li class="py-1">✅ Kreiranje logotipa</li>
                    </ul>
                  </div>

                  {/* <div class="d-flex flex-column justify-content-between mt-auto">
                  <div className="text-center">
                    <h2 class="price">150€</h2>   
                    <a
                      class="btn btn-danger-gradiant rounded-pill text-white btn-md border-0"
                      
                    >
                      270€
                    </a>
                  </div>
                </div> */}
                </div>
              </div>

              <div class="col-md-4">
                <div class="card card-shadow border-0 mb-4">
                  <img
                    class="card-img-top"
                    src="/img/PREMIUM.gif"
                    alt="Premium paket"
                  />
                  <div class="p-3">
                    <h5 class="font-weight-medium mb-0">PREMIUM PAKET</h5>
                    <ul class="list-inline font-14 mt-3">
                      <li class="py-1">✅ Business boost + </li>
                      <li class="py-1">
                        ✅ Dodavanje načina plaćanja na web stranici
                      </li>
                      <li class="py-1">
                        ✅ Neograničen broj izmena na web stranici{" "}
                      </li>
                      <li class="py-1">
                        ✅ Sponzorisane reklame (Facebook, Instagram i Google)
                      </li>
                      <li class="py-1">
                        ✅ Kampanja za odredjeni proizvod ili uslugu
                      </li>
                    </ul>
                  </div>
                  {/* <div class="d-flex flex-column justify-content-between mt-auto">
                  <div className="text-center">
                    <h2 class="price">300€</h2>
                    <a
                      class="btn btn-danger-gradiant rounded-pill text-white btn-md border-0"
                      
                    >
                      300€
                    </a>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mainHome">
          <motion.h2
            variants={textVariants}
            whileHover={{ scale: 1.05, color: "#ff4747" }}
            style={{ position: "relative", display: "inline-block" }}
          >
            Kompletna poslovna rešenja – od strategije do realizacije
            <motion.div
              style={{
                position: "absolute",
                left: 0,
                bottom: -4,
                height: 3,
                width: "100%",
                backgroundColor: "#ff4747",
                scaleX: 0,
              }}
              whileHover={{ scaleX: 1 }}
              transition={{ duration: 0.3 }}
            />
          </motion.h2>

          <motion.p
            variants={textVariants}
            whileHover={{
              scale: 1.02,
              color: "#ff4747",
              textShadow: "0px 0px 10px rgba(255, 71, 71, 0.8)",
            }}
          >
            OglasZona nudi širok spektar usluga koje omogućavaju preduzećima da
            unaprede svoje poslovanje, povećaju vidljivost i optimizuju
            operativne procese...
          </motion.p>

          {/* Service Sections */}
          <motion.div variants={textVariants}>
            <motion.h2 whileHover={{ scale: 1.05, color: "#ff4747" }}>
              1. Poslovni konsalting i automatizacija
            </motion.h2>
            <motion.h4 whileHover={{ scale: 1.03, color: "#ff4747" }}>
              Optimizujte poslovne procese i povećajte efikasnost
            </motion.h4>
            <motion.ul
              variants={containerVariants}
              style={{ listStyle: "none" }}
            >
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Izrada poslovnih strategija – Planiranje i optimizacija
                troškova
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Automtizacija poslovnih procesa – Korišćenje softverskih
                rešenja za uštedu vremena
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Izrada Excel i Google Sheets tabela – Kreiranje prilagođenih
                tabela za finansijsko i operativno upravljanje
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Implementacija CRM sistema – Poboljšanje odnosa s klijentima
                kroz automatizaciju poslovnih procesa
              </motion.li>
            </motion.ul>
          </motion.div>

          <motion.div variants={textVariants}>
            <motion.h2 whileHover={{ scale: 1.05, color: "#ff4747" }}>
              2. Izrada i optimizacija veb-sajtova
            </motion.h2>
            <motion.h4 whileHover={{ scale: 1.03, color: "#ff4747" }}>
              Profesionalni sajtovi koji konvertuju posetioce u klijente
            </motion.h4>
            <motion.ul
              variants={containerVariants}
              style={{ listStyle: "none" }}
            >
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Izrada veb-sajtova – Moderni, responzivni sajtovi prilagođeni
                svim uređajima
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • E-trgovina – Kreiranje online prodavnica sa integrisanim
                sistemima plaćanja
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • SEO optimizacija – Tehnička i sadržajna optimizacija za bolju
                prepoznatljivost na Google-u
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Redizajn i održavanje sajtova – Ažuriranje i optimizacija
                postojećih sajtova
              </motion.li>
            </motion.ul>
          </motion.div>

          {/* Add more sections for the other services here similarly */}

          <motion.div variants={textVariants}>
            <motion.h2 whileHover={{ scale: 1.05, color: "#ff4747" }}>
              3. Brendiranje i vizuelni identitet
            </motion.h2>
            <motion.h4 whileHover={{ scale: 1.03, color: "#ff4747" }}>
              Izgradite prepoznatljiv brend i ostavite snažan utisak
            </motion.h4>
            <motion.ul
              variants={containerVariants}
              style={{ listStyle: "none" }}
            >
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Dizajn logotipa – Profesionalni i unikatni logotipi
                prilagođeni vašem brendu
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Dizajn promotivnog materijala – Vizit karte, flajeri,
                katalozi, posteri
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Brendiranje vozila – Profesionalna grafika i folije za
                promociju vašeg brenda na vozilima
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Brendiranje poslovnog prostora – Reklamne table, svetleće
                reklame, brendiranje izloga
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Izrada prezentacija i ponuda – Profesionalni dokumenti za
                bolju komunikaciju s klijentima
              </motion.li>
            </motion.ul>
          </motion.div>

          <motion.div variants={textVariants}>
            <motion.h2 whileHover={{ scale: 1.05, color: "#ff4747" }}>
              4. Digitalni marketing i oglašavanje
            </motion.h2>
            <motion.h4 whileHover={{ scale: 1.03, color: "#ff4747" }}>
              Povećajte vidljivost i privucite nove klijente
            </motion.h4>
            <motion.ul
              variants={containerVariants}
              style={{ listStyle: "none" }}
            >
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Google Ads kampanje – Ciljano oglašavanje za maksimizaciju
                rezultata
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Facebook i Instagram oglašavanje – Kreiranje i optimizacija
                kampanja za društvene mreže
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • SEO optimizacija – Poboljšanje rangiranja na pretraživačima
                kroz kvalitetan sadržaj i tehničku optimizaciju
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Email marketing – Automatizovane kampanje za povećanje
                angažovanja klijenata
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Remarketing strategije – Ponovno angažovanje korisnika koji su
                već pokazali interesovanje
              </motion.li>
            </motion.ul>
          </motion.div>

          <motion.div variants={textVariants}>
            <motion.h2 whileHover={{ scale: 1.05, color: "#ff4747" }}>
              5. Video produkcija i kreiranje sadržaja
            </motion.h2>
            <motion.h4 whileHover={{ scale: 1.03, color: "#ff4747" }}>
              Kvalitetan vizuelni sadržaj za bolju komunikaciju s klijentima
            </motion.h4>
            <motion.ul
              variants={containerVariants}
              style={{ listStyle: "none" }}
            >
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Promo videi za društvene mreže – Profesionalni video sadržaj
                za Instagram, Facebook i YouTube
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Fotografisanje proizvoda i usluga – Kvalitetne fotografije za
                sajtove i društvene mreže
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Grafički dizajn za digitalne kampanje – Kreiranje vizuelno
                privlačnih oglasa i postova
              </motion.li>
            </motion.ul>
          </motion.div>

          <motion.div variants={textVariants}>
            <motion.h2 whileHover={{ scale: 1.05, color: "#ff4747" }}>
              6. Logistika i transport
            </motion.h2>
            <motion.h4 whileHover={{ scale: 1.03, color: "#ff4747" }}>
              Efikasna rešenja za prevoz i distribuciju
            </motion.h4>
            <motion.ul
              variants={containerVariants}
              style={{ listStyle: "none" }}
            >
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Organizacija transporta građevinskih skela i materijala
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Povezivanje sa pouzdanim transporterima
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Optimizacija ruta i troškova prevoza
              </motion.li>
              <motion.li
                variants={listItemVariants}
                whileHover={{ scale: 1.1, color: "#ff4747" }}
              >
                • Konsalting u oblasti logistike
              </motion.li>
            </motion.ul>
          </motion.div>

          <motion.h4
            className="kontaktirajte"
            whileHover={{ scale: 1.1, color: "#ff4747" }}
          >
            KONTAKTIRAJTE NAS
          </motion.h4>
        </div>
        <MailForm />
      </div>
    </>
  );
};

export default UslugePaketi;
