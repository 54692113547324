import React from "react";
import { motion } from "framer-motion";
import DownloadPdf from "./DownloadPdf"; // Adjust path if necessary

// Variants for animations
const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { staggerChildren: 0.3 } }
};

const textVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { type: "spring", stiffness: 80 } }
};

const listItemVariants = {
  hidden: { opacity: 0, x: -30 },
  visible: { opacity: 1, x: 0, transition: { type: "spring", stiffness: 100 } }
};

const Home = () => {
  return (
    <>
      <div className="home" style={{ height: "100vh" }}></div>
      <DownloadPdf />
      <motion.div
        className="mainHome"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.2 }} // Ensures animations trigger when scrolling
      >
        <motion.h2
          variants={textVariants}
          whileHover={{ scale: 1.05, color: "#ff4747" }}
          style={{ position: "relative", display: "inline-block" }}
        >
          Kompletna podrška za rast vašeg poslovanja
          <motion.div
            style={{
              position: "absolute",
              left: 0,
              bottom: -4,
              height: 3,
              width: "100%",
              backgroundColor: "#ff4747",
              scaleX: 0
            }}
            whileHover={{ scaleX: 1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.h2>

        <motion.p
          variants={textVariants}
          whileHover={{
            scale: 1.02,
            color: "#ff4747",
            textShadow: "0px 0px 10px rgba(255, 71, 71, 0.8)"
          }}
        >
          OglasZona nudi digitalni marketing, izradu sajtova, grafički dizajn i poslovni konsalting. 
          Kroz precizno definisane strategije, pomažemo firmama da poboljšaju vidljivost, unaprede brend i povećaju obim poslovanja.
        </motion.p>

        <motion.ul style={{ listStyle: "none" }} variants={containerVariants}>
          <motion.li variants={listItemVariants} whileHover={{ scale: 1.1, color: "#ff4747" }}>
            • Digitalni marketing i oglašavanje
          </motion.li>
          <motion.li variants={listItemVariants} whileHover={{ scale: 1.1, color: "#ff4747" }}>
            • Izrada i optimizacija veb-sajtova
          </motion.li>
          <motion.li variants={listItemVariants} whileHover={{ scale: 1.1, color: "#ff4747" }}>
            • Brendiranje i vizuelni identitet
          </motion.li>
          <motion.li variants={listItemVariants} whileHover={{ scale: 1.1, color: "#ff4747" }}>
            • Poslovni konsalting i automatizacija
          </motion.li>
          <motion.li variants={listItemVariants} whileHover={{ scale: 1.1, color: "#ff4747" }}>
            • Logistika i transport
          </motion.li>
        </motion.ul>

        <motion.h4
          variants={textVariants}
          whileHover={{ scale: 1.05, color: "#ff4747" }}
        >
          Kontaktirajte nas kako bismo kreirali strategiju prilagođenu vašem poslovanju.
        </motion.h4>
      </motion.div>
    </>
  );
};

export default Home;
