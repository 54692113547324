import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";

const Firme = () => {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter function to check if a row contains the search query
  const filterRow = (rowContent) => {
    return rowContent.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.3 } },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 80 },
    },
  };

  const listItemVariants = {
    hidden: { opacity: 0, x: -30 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: "spring", stiffness: 100 },
    },
  };

  const anyRowsFiltered =
    filterRow("Adaptacija stanova Željko keramicar") ||
    filterRow("Asfaltiranje Radovanović") ||
    filterRow("Rubefix") ||
    filterRow("Limar Pro") ||
    filterRow("Toranj") ||
    filterRow("Gagi Elektro PR elekticar") ||
    filterRow("Znam Coveka Znam Čoveka") ||
    filterRow("Moleraj pistika Moleraj pištika") ||
    filterRow("PVC ZAVRSNE LAJSNE PVC ZAVRŠNE LAJSNE") ||
    filterRow("FAST FOOD KETERING ŽARKOVO") ||
    filterRow("servis i ugradnja klima i ventilacionih sistema") ||
    filterRow("veseli boravak") ||
    filterRow("cleaning fresh") ||
    filterRow("Tattoo studio krcko") ||
    filterRow("Transport zona") ||
    filterRow("Slep sluzba i pomoc na putu šlep služba i pomoć na putu") ||
    filterRow("Soko skele") ||
    filterRow("Construct zona") ||
    filterRow("Div kupatila") ||
    filterRow("Molersko fasaderski radovi") ||
    filterRow(
      "Poljoprivredno gazdinstvo Stojadinovic Poljoprivredno gazdinstvo Stojadinović"
    ) ||
    filterRow("Niski oglasi 018 Niški oglasi 018");

  return (
    <>
      <div className="home" style={{ height: "100vh" }}></div>
      <div>
        <h1 className="noDisplay">Firme</h1>

        <motion.div
          className="mainHome"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.2 }} // Ensures animation runs when scrolling
        >
          <motion.h2
            variants={textVariants}
            whileHover={{ scale: 1.05, color: "#ff4747" }}
            style={{ position: "relative", display: "inline-block" }}
          >
            Preduzeća koja su nam ukazala poverenje
            <motion.div
              style={{
                position: "absolute",
                left: 0,
                bottom: -4,
                height: 3,
                width: "100%",
                backgroundColor: "#ff4747",
                scaleX: 0,
              }}
              whileHover={{ scaleX: 1 }}
              transition={{ duration: 0.3 }}
            />
          </motion.h2>

          <motion.p
            variants={textVariants}
            whileHover={{
              scale: 1.02,
              color: "#ff4747",
              textShadow: "0px 0px 10px rgba(255, 71, 71, 0.8)",
            }}
          >
            OglasZona sarađuje sa firmama iz različitih sektora, pružajući im
            prilagođena rešenja u oblasti digitalnog marketinga, optimizacije
            poslovanja i brendiranja.
          </motion.p>

          <motion.h2
            variants={textVariants}
            whileHover={{ scale: 1.05, color: "#ff4747" }}
            style={{ position: "relative", display: "inline-block" }}
          >
            Sektori u kojima poslujemo
            <motion.div
              style={{
                position: "absolute",
                left: 0,
                bottom: -4,
                height: 3,
                width: "100%",
                backgroundColor: "#ff4747",
                scaleX: 0,
              }}
              whileHover={{ scaleX: 1 }}
              transition={{ duration: 0.3 }}
            />
          </motion.h2>

          <motion.ul style={{ listStyle: "none" }} variants={containerVariants}>
            <motion.li
              variants={listItemVariants}
              whileHover={{ scale: 1.1, color: "#ff4747" }}
            >
              • Građevinska i zanatska delatnost
            </motion.li>
            <motion.li
              variants={listItemVariants}
              whileHover={{ scale: 1.1, color: "#ff4747" }}
            >
              • Maloprodaja i veleprodaja
            </motion.li>
            <motion.li
              variants={listItemVariants}
              whileHover={{ scale: 1.1, color: "#ff4747" }}
            >
              • Ugostiteljstvo i turizam
            </motion.li>
            <motion.li
              variants={listItemVariants}
              whileHover={{ scale: 1.1, color: "#ff4747" }}
            >
              • IT sektor i tehnološke kompanije
            </motion.li>
            <motion.li
              variants={listItemVariants}
              whileHover={{ scale: 1.1, color: "#ff4747" }}
            >
              • Transport i logistika
            </motion.li>
          </motion.ul>
        </motion.div>

        <div className="container nopad">
          <div className="search-container">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
              placeholder="Pretraži firmu"
            />
          </div>

          {anyRowsFiltered ? (
            <>
              {filterRow("Soko skele") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/sokoskele.jpg"
                      alt="Soko skele"
                    />
                  </div>

                  <div
                    className="col ponuda col-12 col-md-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center", // This ensures text is centered horizontally
                    }}
                  >
                    <div>
                      <h2>Soko skele</h2>
                      <p>
                        Skele, oplate, podupirači - najam, transport i montaža
                        na teritoriji cele Srbije.
                      </p>
                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+38162333397" className="noblue">
                        062333397{" "}
                        </a>{" "}
                      </p>


                      <p></p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
            Tel - +381644271971{" "}
          </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}


{filterRow("Div kupatila") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Div kupatila</h2>

                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381605575405" className="noblue">
                        0605575405{" "}
                        </a>{" "}
                      </p>
                      
                      <h3>Lokacija:</h3>
                      <p className="nomargin"> Indjija</p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/divkupatila.jpg"
                      alt="Div kupatila"
                    />
                  </div>
                </div>
              )}

              {filterRow(
                "Slep sluzba i pomoc na putu šlep služba i pomoć na putu"
              ) && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/slepsluzba.jpg"
                      alt="Šlep služba i pomoć na putu"
                    />
                  </div>

                  <div
                    className="col ponuda col-12 col-md-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center", // This ensures text is centered horizontally
                    }}
                  >
                    <div>
                      <h2>Šlep služba i pomoć na putu</h2>
                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381632762122" className="noblue">
                          0632762122{" "}
                        </a>{" "}
                      </p>

                      <h3>Lokacija:</h3>
                      <p className="nomargin"> Kovačica</p>

                      <p></p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
            Tel - +381644271971{" "}
          </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Transport zona") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Transport zona</h2>
                      <p>Transport i logistika</p>

                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a
                          href="mailto: transportzona@gmail.com"
                          className="noblue"
                        >
                          transportzona@gmail.com{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/transportzona.jpg"
                      alt="Transport zona"
                    />
                  </div>
                </div>
              )}

              {filterRow("Construct zona") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/constructzona.jpg"
                      alt="Construct zona"
                    />
                  </div>

                  <div
                    className="col ponuda col-12 col-md-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center", // This ensures text is centered horizontally
                    }}
                  >
                    <div>
                      <h2>Construct zona</h2>

                      <p>
                        <ul style={{ listStyle: "none" }}>
                          <li>• GRADJEVINSKO SAVETOVANJE</li>
                          <li>• SVE VRSTE GRADJEVINSKIH RADOVA </li>
                          <li>• NAJAM I PRODAJA SKELA </li>
                          <li>• NAJAM I PRODAJA KONTEJNERA </li>
                          <li>• TRANSPORT I SELIDBE </li>
                        </ul>
                      </p>

                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+3816050025" className="noblue">
                          06050025{" "}
                        </a>{" "}
                        <br />
                        <a href="tel:+381604753169" className="noblue">
                          0604753169{" "}
                        </a>{" "}
                        <br />
                        <a
                          href="mailto: constructzona@gmail.com"
                          className="noblue"
                        >
                          Mail: constructzona@gmail.com{" "}
                        </a>{" "}
                      </p>

                      <p></p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
            Tel - +381644271971{" "}
          </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Molersko fasaderski radovi") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Molersko fasaderski radovi</h2>

                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381608161251" className="noblue">
                          0608161251{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/vladimirilic.jpg"
                      alt="Molersko fasaderski radovi"
                    />
                  </div>
                </div>
              )}

              {filterRow("Tattoo studio krcko") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/tatoostudio.jpg"
                      alt="Tattoo studio Krcko"
                    />
                  </div>

                  <div
                    className="col ponuda col-12 col-md-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center", // This ensures text is centered horizontally
                    }}
                  >
                    <div>
                      <h2>Tattoo studio Krcko</h2>
                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381613709733" className="noblue">
                          0613709733{" "}
                        </a>{" "}
                      </p>

                      <h3>Lokacija:</h3>
                      <p className="nomargin"> Beograd</p>

                      <p></p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
            Tel - +381644271971{" "}
          </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow(
                "Poljoprivredno gazdinstvo Stojadinovic Poljoprivredno gazdinstvo Stojadinović"
              ) && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Poljoprivredno gazdinstvo Stojadinović</h2>

                      <h3>Lokacija:</h3>
                      <p className="nomargin"> Sokobanja</p>

                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381604838022" className="noblue">
                          0604838022{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/gazdinstvo.jpg"
                      alt="Poljoprivredno gazdinstvo Stojadinović"
                    />
                  </div>
                </div>
              )}

              {filterRow("servis i ugradnja klima i ventilacionih sistema") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/fastenergy.jpg"
                      alt="Servis i ugradnja klima"
                    />
                  </div>

                  <div
                    className="col ponuda col-12 col-md-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center", // This ensures text is centered horizontally
                    }}
                  >
                    <div>
                      <h2>Servis i ugradnja klima i ventilacionih sistema</h2>
                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381617288207" className="noblue">
                          0617288207{" "}
                        </a>{" "}
                      </p>
                      <p></p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
            Tel - +381644271971{" "}
          </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("cleaning fresh") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Cleaning fresh</h2>

                      <p className="nomargin"> Agencija za čišćenje</p>
                      <br />

                      <h3>Lokacija:</h3>
                      <p className="nomargin"> Petlovo Brdo</p>

                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381612263657" className="noblue">
                          0612263657{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/cleaning.jpg"
                      alt="Cleaning fresh"
                    />
                  </div>
                </div>
              )}

              {filterRow("PVC ZAVRSNE LAJSNE PVC ZAVRŠNE LAJSNE") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/lajsne.png"
                      alt="znam"
                    />
                  </div>

                  <div
                    className="col ponuda col-12 col-md-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center", // This ensures text is centered horizontally
                    }}
                  >
                    <div>
                      <h2>PVC ZAVRŠNE LAJSNE</h2>
                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381631343748" className="noblue">
                          0631343748{" "}
                        </a>{" "}
                      </p>
                      <p></p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
            Tel - +381644271971{" "}
          </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Niski oglasi 018 Niški oglasi 018") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Niški oglasi 018</h2>

                      <p className="nomargin">
                        {" "}
                        Besplatan portal za oglašavanje nekretnina, poslova,
                        automobila i drugih kategorija u Nišu i okolini.
                      </p>
                      <br />

                      <h3>Lokacija:</h3>
                      <p className="nomargin"> Niš</p>

                      <h3>Posetite nas:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="https://niskioglasi018.rs/" className="noblue">
                          www.niskioglasi018.rs{" "}
                        </a>{" "}
                      </p>

                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a
                          href="mailto: info@niskioglasi018.rs"
                          className="noblue"
                        >
                          Mail - info@niskioglasi018.rs{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/niskioglasi018.png"
                      alt="Niski oglasi 018"
                    />
                  </div>
                </div>
              )}

              {filterRow("Znam Coveka Znam Čoveka") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/znam.webp"
                      alt="znam"
                    />
                  </div>

                  <div className="col ponuda col-12 col-md-6">
                    <div className="">
                      <p>
                        "Znam Čoveka" je platforma koja povezuje ljude sa
                        proveravanim i pouzdanim uslugama i stručnjacima. Nudi
                        širok spektar informacija i preporuka kako biste lako
                        pronašli kvalitetne usluge i izbegli neproverene
                        ponuđače. Idealno rešenje za sve vaše potrebe i pitanja,
                        sa fokusom na poverenje i kvalitet.
                      </p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
                          Tel - +381644271971{" "}
                        </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Moleraj pistika Moleraj pištika") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Moleraj pištika</h2>

                      <p className="nomargin">
                        {" "}
                        Sa višegodišnjim iskustvom, garantujemo visokokvalitetan
                        rad uz pažljivu pripremu površine i Vaš enterijer
                        osvežićemo našim materijalom bez potrebe da ga Vi
                        kupujete. Nedopustite da Vaši molerski i gipsarski
                        radovi budu izvor stresa, kontaktirajte nas danas i
                        osigurajte sebi besprekoran završetak Vašeg projekta.
                      </p>
                      <p className="nomargin">
                        Molerski radovi Pištika Vama na usluzi.
                      </p>
                      <br />
                      <h3>Kontakt:</h3>

                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381638412029" className="noblue">
                          Tel - +381638412029{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/pistika.webp"
                      alt="Moleraj pistika"
                    />
                  </div>
                </div>
              )}

              {filterRow("Adaptacija stanova Željko keramicar") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/keramicar.webp"
                      alt="Keramicar"
                    />
                  </div>

                  <div className="col ponuda col-12 col-md-6">
                    <div className="">
                      <h2>Adaptacija stanova Željko</h2>
                      <h3>Cenovnik adaptacija stana:</h3>
                      <p>Klasična adaptacija 300e </p>
                      <p>Polu lux adaptacija 400e</p>
                      <p>Lux adaptacija 500e</p>
                      <p>Kvadrat keramike 17e</p>
                      <p>Kupatilo komplet 4000e</p>
                      <p>
                        Cene su date za sistem ključ u ruke sa materijalom i
                        majstorima po kvadratu stambenog prostora.
                      </p>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381644271971" className="noblue">
                          Tel - +381644271971{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Asfaltiranje Radovanović") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Asfaltiranje Radovanović</h2>
                      <h3>O nama</h3>

                      <p className="nomargin">
                        {" "}
                        Asfaltiranje Radovanović ima višegodišnje iskustvo u
                        pripremi i asfaltiranju. Naš stručni kadar se sastoji od
                        ljudi koji imaju preko 15 godina radnog iskustva što
                        pokazuje preko 300 uspešno obavljenih poslova bez ijedne
                        reklamacije.{" "}
                      </p>
                      <br />
                      <h3>Kontakt:</h3>

                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381601439074" className="noblue">
                          Tel - +381601439074{" "}
                        </a>{" "}
                      </p>
                      <p className="nomargin">
                        <a
                          href="mailto: milosradovanovic484@gmail.com"
                          target="_blank"
                          rel="noreferrer"
                          className="noblue"
                        >
                          {" "}
                          Email - milosradovanovic484@gmail.com
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/asfalt.jpg"
                      alt="Asfaltiranje Radovanovic"
                    />
                  </div>
                </div>
              )}

              {filterRow("Rubefix") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/rubefix.jpg"
                      alt="Rubefix"
                    />
                  </div>

                  <div className="col ponuda col-12 col-md-6">
                    <div className="">
                      <h2>Rubefix</h2>
                      <p>
                        Rube Clean je agencija za čišćenje stanova, poslovnih
                        prostora i mašinskog čišćenja hala. Cilj naše agencije
                        je da svojim klijentima pružimo profesionalne usluge
                        čišćenja visokog kvaliteta. Naš tim se sastoji od
                        stručnih i obučenih čistača koji će se pobrinuti da vaš
                        prostor sjaji. Koristimo najnoviju opremu i tehnologiju
                        u procesu čišćenja, što nam omogućava da postignemo
                        visok nivo efikasnosti i preciznosti. Pored toga, naša
                        agencija pruža i usluge mašinskog čišćenja hala, gde
                        ćemo se pobrinuti da vaša industrijska hala bude
                        besprekorno čista. Kontaktirajte nas i uverite se u našu
                        posvećenost čistoći i kvalitetu usluge koju pružamo.
                      </p>
                      <h3>Usluge</h3>
                      <p>
                        Čiscenje zgrada, mašinsko čišćenje , čišćenje posle
                        gradjevinskih radova,čišćenje kancelarijskog
                        prostora,održavanje zelenih površina...
                      </p>
                      <p>
                        <i>
                          <a
                            href="mailto: info@rube.rs"
                            target="_blank"
                            rel="noreferrer"
                            className="noblue"
                          >
                            info@rube.rs
                          </a>
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Limar Pro") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Limar Pro</h2>
                      <h3>Usluge</h3>

                      <p className="nomargin"> Horizontalne oluke </p>
                      <p className="nomargin"> Odvodne olucne vertikale</p>
                      <p className="nomargin"> Sve vrste krovnih opsivki</p>
                      <p className="nomargin"> Pokrivanje krova limom</p>
                      <p className="nomargin">
                        {" "}
                        Ugradnja termoizolacionih krovnih panela
                      </p>
                      <p className="nomargin"> Ugradnja zidnih termo panela</p>
                      <p className="nomargin"> Krovne izolacije</p>
                      <p className="nomargin"> Krovne konstrukcije</p>
                      <h3>Kontakt:</h3>

                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381631050336" className="noblue">
                          Tel - +381631050336{" "}
                        </a>{" "}
                      </p>
                      <p className="nomargin">
                        <a
                          href="mailto: belajec@gmail.com"
                          target="_blank"
                          rel="noreferrer"
                          className="noblue"
                        >
                          {" "}
                          Email - belajec@gmail.com
                        </a>{" "}
                      </p>
                      <p className="nomargin"> FB page - Limar - Pro</p>
                    </div>
                  </div>

                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/limarpro.jpg"
                      alt="Limarpro"
                    />
                  </div>
                </div>
              )}

              {filterRow("Toranj") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/toranj.webp"
                      alt="Toranj"
                    />
                  </div>

                  <div className="col ponuda col-12 col-md-6">
                    <div className="">
                      <h2>Toranj</h2>
                      <h3>Vladimir Veselinovic</h3>
                      <p>Iz Obrenovca, vrši usluge širom Srbije </p>
                      <p>Ispitivanje zemljišta, uzrokovanje i bušenje bunara</p>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+38163274757" className="noblue">
                          Tel - +38163274757{" "}
                        </a>{" "}
                      </p>
                      <p>
                        <i>
                          <a
                            href="mailto: vvladimir@beotel.net "
                            target="_blank"
                            rel="noreferrer"
                            className="noblue"
                          >
                            vvladimir@beotel.net
                          </a>
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Gagi Elektro PR elekticar") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Gagi Elektro PR</h2>

                      <h3>Usluge</h3>
                      <p className="nomargin">
                        {" "}
                        Sve vrste elektronskih usluga{" "}
                      </p>

                      <h3>Mesto</h3>
                      <p className="nomargin"> Beograd </p>

                      <h3>Mesto rada</h3>
                      <p className="nomargin"> Šire područje Beograda</p>

                      <h3>Kontakt:</h3>

                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381643278044" className="noblue">
                          Tel - +381643278044{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/gagi.jpg"
                      alt="Gagi Elektro"
                    />
                  </div>
                </div>
              )}

              {filterRow("veseli boravak") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/veseliboravak.jpg"
                      alt="Veseli boravak"
                    />
                  </div>

                  <div
                    className="col ponuda col-12 col-md-6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center", // This ensures text is centered horizontally
                    }}
                  >
                    <div>
                      <h2>Veseli boravak</h2>
                      <p>
                        Veseli boravak, produženi boravak za decu od 3-11
                        godina. Naš boravak je nov, moderno opremljen sa
                        učionicom i stolovima za decu, kao i prijatnim prostorom
                        za igru.
                      </p>
                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+38162663197" className="noblue">
                          062663197{" "}
                        </a>{" "}
                      </p>
                      <h3>Lokacija:</h3>
                      <p className="nomargin"> Vidikovački venac 2D</p>
                      <p></p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
            Tel - +381644271971{" "}
          </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("FAST FOOD&KETERING ŽARKOVO") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      display: "flex", // Added flexbox to center the content
                      flexDirection: "column", // Stacks content vertically
                      justifyContent: "center", // Centers content vertically
                      alignItems: "center", // Centers content horizontally
                      textAlign: "center", // Centers the text inside the div
                    }}
                  >
                    <div className="">
                      <h2>FAST FOOD&KETERING ŽARKOVO</h2>

                      <h3>Kontakt:</h3>
                      <p className="nomargin">
                        {" "}
                        <a href="tel: +381631950673" className="noblue">
                          Telefon - 0631950673{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/picasarmica.jpg"
                      alt="Pica sarmica"
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <h2 style={{ textAlign: "center" }}>Nema rezultata pretrage</h2>
          )}
        </div>
      </div>
    </>
  );
};

export default Firme;
