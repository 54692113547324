import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SocialIcons = () => {
  return (
    <div className="w-100" style={{ backgroundColor: 'rgba(155, 17, 30, 0.7)' }}>
      <div className="container d-flex align-items-center justify-content-center py-4">
        <div className="row w-100" style={{ maxWidth: '800px' }}>
          {/* Left Section: Social Icons */}
          <div className="col-md-4 d-flex justify-content-center justify-content-md-start align-items-center">
            <ul className="list-unstyled d-flex gap-3 mb-0" id="social-icons">
              <li>
                <a href="https://www.facebook.com/oglaszona?mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                  <i className="fab fa-facebook-f icon"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/oglas_zona_rs?igsh=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
                  <i className="fab fa-instagram icon"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/oglas-zona-704a682aa?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noreferrer">
                  <i className="fab fa-linkedin-in icon"></i>
                </a>
              </li>
              <li>
                <a href="mailto:oglaszona@proton.me" target="_blank" rel="noreferrer">
                  <i className="fab fa-google icon"></i>
                </a>
              </li>
            </ul>
          </div>
          
          {/* Middle Divider (Hidden on Small Screens) */}
          <div className="col-md-4 d-flex justify-content-center align-items-center d-none d-md-flex">
            <div className="border-end border-white" style={{ height: '100px', width: '2px' }}></div>
          </div>
          
          {/* Right Section: Email & Phone */}
          <div className="col-md-4 text-white d-flex flex-column justify-content-center text-md-end text-center">
              <p className="mb-1"><a href="mailto:oglaszona@gmail.me" className="noblue">oglaszona@gmail.me</a></p>
              <p className="mb-1"><a href="mailto:constructzona@gmail.com" className="noblue">constructzona@gmail.com</a></p>
              <p className="mb-1"><a href="mailto:usersupport@gmail.com" className="noblue">usersupport@gmail.com</a></p>          
              <p className="mb-0"> <a href="tel:+381604753169" className="noblue">060 4753 169</a></p>
              <p className="mb-1"><a href="tel:+381605060025" className="noblue">060 5060 025</a></p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default SocialIcons;
