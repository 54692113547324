import React, { useState, useEffect } from 'react';
import './SplashScreen.css';
// import 'animate.css';

const SplashScreen = () => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = 'hidden';

    const timer = setTimeout(() => {
      setShowSplash(false);
      // Enable scrolling again
      document.body.style.overflow = 'auto';
    }, 2500); // Koliko traje splash

    return () => {
      clearTimeout(timer);
      document.body.style.overflow = 'auto'; // Ensure scrolling is enabled if component unmounts
    };
  }, []);

  if (!showSplash) return null;

  return (
    <div className="splash-screen">
      <img
        src="/img/gifoglaszona.gif"
        alt="Splash"
        // className="animate__animated animate__zoomIn"
        style={{ width: '100%', height: 'auto' }}
      />
    </div>
  );
};

export default SplashScreen;
